.kpi {
  background-color: #F1F1F1;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(#000000, .15);
  box-sizing: border-box;
  margin-bottom: 30px;
  padding: 15px;
  position: relative;
  text-align: right;
  transition: box-shadow .2s ease-in-out;
  user-select: none;

  &:hover {
    box-shadow: 0 5px 8px rgba(#000000, .25);
  }

  &:before {
    background-color: #009CA3;
    border-radius: 6px;
    content: '';
    display: block;
    height: calc(100% - 26px);
    left: 12px;
    position: absolute;
    top: 13px;
    width: 6px;
  }

  &__value {
    color: #009CA3;
    display: block;
    font-size: 24px;
    font-weight: 600;

    &-prefix {
      font-size: 16px;
    }
  }

  &__label {
    color: #666666;
    display: block;
    font-size: 13px;
  }

  &--loading {
    &:before {
      background-color: rgba(0, 0, 0, .15);
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:after {
      animation-delay: none;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: loadingSpin;
      background: transparent;
      border: 5px solid #666666;
      border-top-color: #009CA3;
      border-radius: 50%;
      content: '';
      display: block;
      height: 40px;
      left: calc(50% - 20px);
      position: absolute;
      top: calc(50% - 20px);
      width: 40px;
    }
  }

  @media (max-width: 992px) {
    padding: 7.5px;

    &:before {
      left: 8px;
      width: 4px;
    }

    &__value {
      font-size: 20px;
    }

    &__label {
      font-size: 11px;
    }
  }
}

@keyframes loadingSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}