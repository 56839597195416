.datepicker {
	display: inline-block;
	float: right;
	position: relative;
	z-index: 99;

	&__toggle {
		appearance: none;
		background: transparent;
		background-color: #FFFFFF;
		border: 0;
		border-radius: 25px;
		box-sizing: border-box;
		box-shadow: 0 3px 6px rgba(#000000, .16);
		color: #666666;
		cursor: pointer;
		display: block;
		font-size: 18px;
		font-weight: 600;
		height: 50px;
		line-height: 50px;
		margin: 0;
		margin-left: auto;
		outline: none;
		padding: 0 20px;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		transition: background-color .2s ease-in-out;
		width: 250px;
		z-index: 1;

		&:hover, &--on {
			background-color: darken(#FFFFFF, 5);
		}
	}

	&__container {
		background-color: #FFFFFF;
		border-radius: 6px;
		box-shadow: 0 4px 8px rgba(#000000, .25);
		box-sizing: border-box;
		display: none;
		padding: 15px;
		position: absolute;
		right: 0;
		top: calc(100% + 10px);
		white-space: nowrap;
		z-index: 2;

		&--show {
			display: block;
		}
	}

	&__set {
		appearance: none;
		background: transparent;
		background-color: #2B0D61;
		border: 0;
		border-radius: 20px;
		box-sizing: border-box;
		box-shadow: 0 3px 6px rgba(#000000, .20);
		color: #FFFFFF;
		cursor: pointer;
		display: block;
		font-size: 16px;
		font-weight: 600;
		height: 40px;
		line-height: 40px;
		margin: auto;
		margin-top: 1rem;
		outline: none;
		text-align: center;
		text-transform: uppercase;
		transition: background-color .2s ease-in-out;
		width: 185px;
	}

	&__unset {
		appearance: none;
		background: transparent;
		background-color: #FFFFFF;
		border: 0;
		border-radius: 50%;
		box-sizing: border-box;
		box-shadow: 0 3px 6px rgba(#000000, .16);
		color: #666666;
		cursor: pointer;
		display: none;
		font-size: 30px;
		font-weight: 400;
		height: 33px;
		line-height: 33px;
		margin: 0;
		outline: none;
		padding: 0;
		position: absolute;
    right: 20px;
		text-align: center;
    top: calc(50% - 16.5px);
		width: 33px;
		z-index: 1;
	}

	&--same-month {
		.react-datepicker:first-of-type .react-datepicker__navigation--next,
		.react-datepicker:last-of-type .react-datepicker__navigation--previous {
			display: none;
		}
	}

	&--period-set &__toggle {
		background-color: darken(#FFFFFF, 5);
		font-size: 14px;
		text-align: left;
	}

	&--period-set &__unset {
		display: block;
	}

	&--centered {
		float: none;
	}

	&--centered &__toggle {
		margin: auto;
	}
}

.react-datepicker {
	background: transparent;
	border: 0;
	border-radius: 0;
	color: #43425D;
	font-family: 'Montserrat', sans-serif;

	&__header {
		background: transparent;
		border: 0;
		border-radius: 0;

		&:after {
			background-color: rgba(#000000, .15);
			border-radius: 5px;
			content: '';
			display: block;
			height: 1px;
			margin: auto;
			width: 90%;
		}
	}

	&__current-month {
		color: #666666;
		font-weight: 500;
		margin-bottom: 15px;
		text-transform: capitalize;
	}

	&__day {
		color: #43425D;
		line-height: 2rem;
		width: 2rem;

		&:hover {
			border-radius: 50%;
		}

		&--selected, &--selected:hover {
			background-color: rgba(#999999, .4);
			border-radius: 50%;
			// color: #005D61;
			font-weight: 800;
		}
	}

	&__navigation--next,
	&__navigation--previous {
		outline: none;
	}
}