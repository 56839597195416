.total-on-period {
  margin-bottom: 15px;

  &__title {
    color: #666666;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__total {
    display: inline-block;

    &:nth-of-type(1) {
      margin-right: 30px;
    }
  }

  &__total-value {
    color: #009CA3;
    display: block;
    font-size: 24px;
    font-weight: 600;
  }

  &__total-label {
    color: #666666;
    display: block;
    font-size: 12px;
    font-weight: 400;
    text-align: right;
  }
}