.stats-table {
	&__body {
		box-sizing: border-box;
		display: flex;
		flex-flow: column nowrap;
		position: relative;
	}

	&__row {
		box-sizing: border-box;
		display: flex;
		flex: 1 0 auto;

		&:not(:last-child):not(#{&}--borderless) {
			border-bottom: solid 1px #999999;
		}

		&--empty:not(:last-child) {
			border-bottom: solid 1px rgba(#999999, .3);
		}

		&--borderless {
			border: 0;
		}
	}

	&__cell {
		box-sizing: border-box;
		color: #666666;
    flex: 1 0;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    padding: 15px 5px;
    text-align: center;
    text-overflow: ellipsis;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
		white-space: nowrap;

		&:first-child {
			text-align: left;
		}

		&--header {
			color: #333333;
			font-size: 16px;
			font-weight: 600;
			line-height: normal;
			padding: 5px 5px;
		}

		&--empty {
			height: 46px;
		}
	}

	&__loader {
		align-items: center;
		background-color: rgba(0, 0, 0, .035);
		display: flex;
		filter: blur(50%);
		height: 100%;
		justify-content: center;
		left: -15px;
		position: absolute;
		top: 0;
		width: calc(100% + 30px);
		z-index: 1;
	}

	&__footer {
		align-items: center;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		padding-top: 10px;
	}

	&__page-size-selector {
		// appearance: none;
		background: transparent;
		background-color: #FAFAFA;
		border: 0;
		border-radius: 4px;
		box-sizing: border-box;
		color: #666;
		display: inline-block;
		height: 31px;
		line-height: 31px;
		margin-left: 10px;
		outline-color: #009CA3;
		padding: 7px;
		text-align: center;
		width: 51px;

		&:first-child {
			margin-right: 10px;
		}

		&:disabled, &--disabled {
			opacity: .5;
		}

		option[selected], option:checked, option:hover {
			background: #009CA3;
			box-shadow: 0 0 10px 100px #009CA3 inset;
			color: white;
			outline-color: #009CA3;
		}
	}

	&__pagination-btn {
		appearance: none;
		background: transparent;
		background-color: #FAFAFA;
		border: 0;
		border-radius: 4px;
		box-sizing: border-box;
		color: #666;
		cursor: pointer;
		display: inline-block;
		height: 31px;
		line-height: 31px;
		outline: none;
		padding: 0;
		text-align: center;
		transition: all .15s ease-in-out;
		width: 31px;

		&:first-child {
			margin-right: 10px;
		}

		&:hover {
			background-color: darken($color: #FAFAFA, $amount: 10);
		}

		&:disabled, &--disabled {
			cursor: default;
			opacity: .5;

			&:hover {
				background-color: #FAFAFA;
			}
		}
	}

	&__search {
		margin-bottom: 20px;
		position: relative;
		width: 100%;

		&-label {
			border: 0;
			clip: rect(0,0,0,0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px;
		}

		&-input {
			appearance: none;
			background: transparent;
			background-color: #F1F1F1;
			border: 0;
			border-radius: 4px;
			color: #999999;
			display: block;
			font-size: 16px;
			font-weight: 600;
			height: 42px;
			line-height: 42px;
			margin: 0;
			outline-color: #009CA3;
			padding: 0 15px;
			width: 100%;
		}

		&-btn {
			appearance: none;
			background: transparent;
			border: 0;
			border-radius: 4px;
			box-sizing: border-box;
			color: #999999;
			cursor: pointer;
			display: inline-block;
			font-size: 16px;
			height: 16px;
			line-height: 16px;
			outline: none;
			padding: 0;
			position: absolute;
			right: 15px;
			text-align: center;
			top: calc(50% - 8px);
			width: 16px;
		}
	}
}