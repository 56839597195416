@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700');

@import '../node_modules/normalize.css/normalize.css';
@import '../node_modules/bootstrap/scss/bootstrap-grid.scss';
@import '../node_modules/animate.css/animate.css';

body {
  background-color: #FAFAFA;
  font-family: 'Montserrat', sans-serif;
}

.container-fluid {
  max-width: 1600px;
}

.chart {
  rect {
    rx: 2px;
    ry: 2px;
  }

  svg {
    > g:nth-of-type(1):nth-child(3) {
      transform: translateY(-10px);
    }
    > g:nth-of-type(1) > g {
			cursor: pointer;
    }
  }
}