.title {
  margin-top: 15px;

  &--hero {}

  &--main {}

  &--section {
    color: #666666;
    font-size: 24px;
    font-weight: 600;
  }
}