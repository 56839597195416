.loader {
	&--dots {
		text-align: center;
		width: 70px;

		span {
			background-color: #009CA3;
			border-radius: 100%;
			display: inline-block;
			height: 16px;
			width: 16px;

			&:nth-child(1) {
				animation-delay: -0.32s;
				animation-duration: 1.4s;
				animation-fill-mode: both;
				animation-iteration-count: infinite;
				animation-name: fill;
				animation-timing-function: ease-in-out;
			}

			&:nth-child(2) {
				animation-delay: -0.16s;
				animation-duration: 1.4s;
				animation-fill-mode: both;
				animation-iteration-count: infinite;
				animation-name: fill;
				animation-timing-function: ease-in-out;
			}

			&:nth-child(3) {
				animation-delay: 0;
				animation-duration: 1.4s;
				animation-fill-mode: both;
				animation-iteration-count: infinite;
				animation-name: fill;
				animation-timing-function: ease-in-out;
			}
		}
	}

	&--centered {
		margin: auto;
	}
}

@keyframes fill {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1.0);
	}
}